<template>
  <div>
    <validation-observer ref="loginValidation">
      <div class="form-heading">
        <div class="form-heading-emoji-wrapper">
          <img src="/emoji/key.png" alt="confetti-img">
        </div>
        <div class="form-title">Change Your Password</div>
      </div>

      <b-form @submit.prevent="sendEmail">
        <validated-form-input
            label="New Password"
            input-id="password-input"
            validation-name="password"
            placeholder="Enter your password"
            type="password"
            v-model="password"
        />
        <validated-form-input
            label="Confirm New Password"
            input-id="password-input"
            validation-name="password"
            placeholder="Enter your password"
            type="password"
            v-model="confirmPassword"
        />

        <b-button type="submit" class="w-100 mt-1 mt-md-2 submit-form-btn" :disabled="loading">
          <template v-if="loading">
            <b-spinner small class="mr-50"/>
            <span class="align-middle">Loading</span>
          </template>
          <span v-else>Update Password</span>
        </b-button>

        <p class="text-center mt-2"><span>Remember your password? </span>
          <router-link :to="{ name: 'auth.login' }"><span> Sign In</span></router-link>
        </p>
      </b-form>
    </validation-observer>
  </div>
</template>


<script>
import {ValidationObserver} from "vee-validate";
import {BForm, BButton, BSpinner} from "bootstrap-vue";
import AuthCardLeft from "@/layouts/components/auth/AuthCardLeft";
import ValidatedFormInput from "@/layouts/components/shared/ValidatedFormInput";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import AuthService from "@/services/AuthService";

export default {
  name: 'AuthLogin',
  components: {
    BForm,
    BButton,
    BSpinner,
    ValidationObserver,
    AuthCardLeft,
    ValidatedFormInput
  },
  data() {
    return {
      userEmail: '',
      password: '',
      confirmPassword: '',
      loading: false
    }
  },
  methods: {
    resetForm() {
      this.userEmail = '';
      this.password = '';
      this.$refs.loginValidation.reset();
    },
    redirectAuthUserToAccordingRoute() {
      if (!this.$store.getters['auth/hasActiveSubscription']) {
        return this.$router.push({name: 'auth.setup-payments'});
      }

      if (this.$store.getters['auth/hasActiveSubscription'] && !this.$store.getters['auth/hasSetupApiKey']) {
        return this.$router.push({name: 'auth.setup-api-key'});
      }

      if (this.$store.getters['auth/hasActiveSubscription'] && this.$store.getters['auth/hasSetupApiKey'] && !this.$store.getters['auth/hasConfiguredLocations']) {
        return this.$router.push({name: 'auth.setup-locations'});
      }

      return this.$router.push({name: 'dashboard'});
    },
    async sendEmail() {
      const isFormValid = this.$refs.loginValidation.validate();

      if (!isFormValid || this.loading) return;

      try {
        this.loading = true;
        // Token is passed as a query param
        const {token} = this.$route.query;
        await AuthService.post.updatePassword(token, this.password, this.confirmPassword);
        this.loading = false;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Password successfully updated!',
            icon: 'CheckIcon',
            variant: 'success',
            hideClose: true
          },
        });

        await this.$router.push({name: 'auth.login'});
      } catch (err) {
        this.loading = false;
        if (err.response) {
          this.resetForm();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.response.data.message,
              icon: 'XIcon',
              variant: 'danger',
              hideClose: true
            },
          });
        }
      }
    }
  }
}
</script>
